@import "material-icons/iconfont/material-icons.css";
@import "https://cdn.skypack.dev/@studio-freight/lenis@0.1.12";


:root{
  background-color: rgba(193, 157, 86, 0.273);
  color: #fff;
  /* zoom:90%; */

}
:root::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: var(--background);
    background-size: 20%;
    background-position-x: 100%;
    background-repeat: no-repeat;
    opacity: 0.5;
    position: fixed;
  
}

.App {
  border-radius: 12px;
  position: relative;
  width: 100%;
}

:root.brollop {
  /* --background: url(ellinor2.jpg); */
}

:root.begravning {
  /* --background: url(ellinor3.jpg); */
}

:root.dop {
  /* --background: url(ellinor4.jpg); */
}

:root.ovrigt {
  /* --background: url(ellinor5.jpg); */
}

:root.home {
  --background: url(guitarr.png);
}

@media screen and (max-width: 1000px) {
  

  :root::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: url(guitarr.png);
    background-size: 60%;
    background-position-x: 100%;
    background-position-y: 0cm;

    background-repeat: no-repeat;
    opacity: 0.5;
    position: fixed;
  }
}
